//frameworks
import React, {Component, useRef, useEffect } from 'react';
import { Container, InputGroup, FormControl } from 'react-bootstrap';
//Components
import {configuracaoHeader} from '../../Controller/paginaDeConfiguracao';
//CSS
import './index.css';



class TelaLogin extends Component{
    constructor(props){
        super(props);
        this.myRef = React.createRef();
        this.state = {
            user: '',
            senha: ''
        }
    }

    render(){
        return(
            <div id="componente-login">
                <Container>
                    <section id="grupo-logo">
                        <center id='div-logo'>
                            <img src={configuracaoHeader.logo} />
                            <h2>Diga-me algo sobre você!</h2>
                        </center>                        
                    </section>
                    <section id="grupo-form">
                        <center>
                            <form>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">Seu Usuário</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Usuário"
                                        aria-label="Usuário"
                                        aria-describedby="basic-addon2"
                                    />
                                </InputGroup>


                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">Sua Senha</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Senha"
                                        aria-label="Senha"
                                        aria-describedby="basic-addon2"
                                    />
                                </InputGroup>
                            </form>                            
                        </center>                        
                    </section>   
                </Container>
            </div>
        );
    }
}

export default TelaLogin;