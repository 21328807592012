import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Header from './Content/Header';
import Footer from './Content/Footer';
import Home from './Home';
import ComoFuncionaEnergiaSolar from './Content/ComoFuncionaEnergiaSolar';
import PaginaEmConstrucao from './Content/PaginaEmConstrucao';
import TelaLogin from './Content/TelaLogin';
import ErroLogin from './ErroLogin';

import {configuracaoFooter, configuracaoHeader} from './Controller/paginaDeConfiguracao';


const Routes = () =>{
    return(
        <BrowserRouter>
            <Header configsHeader = {configuracaoHeader}/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/comoFuncionaEnergiaSolar" component={ComoFuncionaEnergiaSolar}/>
                <Route exact path='/paginaEmConstrucao' component={PaginaEmConstrucao}/>
                <Route exact path='/login' component={TelaLogin}/>
                <Route exact path='/erroLogin' component={ErroLogin}/>
                <Route path="*" component={Home}/>
            </Switch>
            <Footer configsFooter = {configuracaoFooter} />   
        </BrowserRouter>
    );
}

export default Routes;