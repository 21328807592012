import React from 'react';
import { Component } from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.css';

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = props.configsFooter;
    }
    render(){
        return(
            <footer>
                <Container>
                    <article>
                        <center >
                            <a href={this.state.linksRedesSociais.facebook.link} className="links-redes_sociais">
                                <FontAwesomeIcon icon={this.state.linksRedesSociais.facebook.icone}/>
                            </a>
                            <a href={this.state.linksRedesSociais.whatsapp.link} className="links-redes_sociais">
                                <FontAwesomeIcon icon={this.state.linksRedesSociais.whatsapp.icone}/>
                            </a> 
                            <a href={this.state.linksRedesSociais.linkedin.link} className="links-redes_sociais">
                                <FontAwesomeIcon icon={this.state.linksRedesSociais.linkedin.icone}/>
                            </a> 
                            <a href={this.state.linksRedesSociais.instagram.link} className="links-redes_sociais">
                                <FontAwesomeIcon icon={this.state.linksRedesSociais.instagram.icone}/>
                            </a>       
                        </center>
                    </article>  
                    <Row md='3' xs='1'>
                        <Col>                       
                            <h4>{this.state.colunaEsquerda.titulo}</h4>
                            {this.state.colunaEsquerda.linhas.map((linha) => {
                                return(
                                    <p key={linha.key}>{linha.texto}</p>
                                );
                            })} 
                            <hr />
                        </Col>
                        <Col>
                            <h4>{this.state.colunaCentral.titulo}</h4>
                            {this.state.colunaCentral.linhas.map((linha) => {
                                return(
                                    <p key={linha.key}>{linha.texto}</p>
                                );
                            })} 
                            
                        </Col>
                        <Col className="coluna-direita">
                            <p>Site desenvolvido por:</p>
                            <h5>Eli Somorovski Medeiros</h5>
                            <h5>Sol&Vento&lt;Soft\&gt;</h5>
                            <p>Email: contato.solevento@gmail.com</p>
                            <hr />
                        </Col>
                    </Row>
                </Container>                
            </footer>
        );
    };
}

export default Footer;