import React from 'react';
import {Component} from 'react';
import Container from './Content/Container';

import {configuracaoContainer} from './Controller/paginaDeConfiguracao';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      configsContainer: configuracaoContainer
    }
  }

  render(){
    return(
      <div>        
        <Container configsContainer={this.state.configsContainer}/>             
      </div>
    )
  }
}

export default App;
