import React from 'react';
import { Component } from 'react';

class PaginaEmConstrucao extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <center>
                <img src="./imagens/construcao.png" alt="Pagina em Construção"/>
                <h2> Calma que eu sou um só!!</h2>
                <p>Ainda não deu tempo de colocar nada aqui!! Mas daqui a pouquinho já aparece.</p>
            </center>
        );
    }
}

export default PaginaEmConstrucao;
