import React from 'react';
import {Component} from 'react';
import {Link} from 'react-router-dom';

import {Container} from 'react-bootstrap';

import './index.css';

class ComoFuncionaEnergiaSolar extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return (
            <Container>
                <article>
                    <section  id="como_funciona" align="justify" class="container-800px segunda_parte">
                        <h2>Como funciona a energia solar?</h2>
                        <p>&nbsp;&nbsp; As placas solares são compostas por duas finas lâminas de silício, o mesmo utilizado em componentes eletrônicos. Esse material tem
                            capacidade de absorver a luz, principalmente a luz visível e um pouco ainda do infravermelho e do ultravioleta. Cada foton de luz 
                            absorvido provoca o deslocamento de um elétron do silício de uma lâmina para a outra, criando assim uma tensão elétrica de aproximamente
                            0,6V entre elas. Assim, somando as tensões das divesas células existentes em cada módulo do arranjo foto voltaico chega-se a tensão de 
                            trabalho normal da usina solar, que varia normalmente entre 100 e 1000V contínuos para sistemas conectados à rede da concenssionária 
                            (on grid) e entre 14V e 60V para sistemas isolados (off grid). 
                        </p>
                        <center>
                            <img src="./imagens/comoEnergiaSolarFunciona/sensibilidade-espectral.jpg" alt="Sensibilidade Spectral dos Módulos Solares"/>
                        </center>
                        <div class="retornar">
                            <a href="#cabecalho">                        
                                Voltar ao início
                            </a>
                        </div>
                    </section>
                
                    <section  id="na_pratica" align="justify" class="container-800px segunda_parte">
                        <h2>Mas na prática, como funcionaria na minha casa?</h2>
                        <h2>Usina On Grid</h2>
                        <p>&nbsp;&nbsp; O sistema mais comum, utilizado na ampla maioria das instalações de energia solar é o on grid. Nele sua usina de energia solar
                            é ligada em conjunto com a rede da concenssionária. Nesse tipo de ligação, em momentos de boa geração de energia (momentos de boa irradiação solar)
                            ela supre sua casa com a eletricidade que precisa e o excedente é enviado para a rede pública. Quando é realizada a medição do seu consumo 
                            elétrico uma vez por mês, é lido o valor consumido e o valor gerado. Caso o valor gerado seja maior, a diferença se torna bônus para ser utilizado em meses de
                            pouco sol. A única desvantagem desse sistema é a de que para evitar acidentes com os técnicos da concenssionária, o sistema se bloqueia em 
                            caso de falha no fornecimento de energia, porém, como você lerá abaixo, esse problema é corrigido utilizando-se o sistema hibrido on e off grid.
                        </p>
                        <center>
                            <img src="./imagens/comoEnergiaSolarFunciona/ongrid.jpeg" alt="Como funciona uma instalação ongrid" />
                        </center>
                        <div class="retornar">
                            <a href="#cabecalho">                        
                                Voltar ao início
                            </a>
                        </div>
                        <h2>Usina Off Grid</h2>
                        <p>&nbsp;&nbsp; No sistema off grid a geração de energia ocorre de maneira totalmente independente da rede externa, nela são utilizados bancos de baterias
                            que fornecem a energia em momentos de pouca geração. Devido ao custo e maior necessidade de manutenção, esse tipo de instalação é mais comumente utilizado
                            em locais não atendidos pela concenssionária de energia.</p>
                        <center>
                            <img src="./imagens/comoEnergiaSolarFunciona/offgrid.png" alt="Como funciona uma instalação offgrid" />
                        </center>
                        <h2>Usina hibrida on e off grid</h2>
                        <p>&nbsp;&nbsp; Também existe o sistema hibrido on e off grid, ele reúne os aspéctos positivos dos dois tipos de usinas. Por exemplo, esse 
                            tipo de usina trabalha conectada à rede externa, lançando para ela o excedente da produção, assim como a on grid, porém ela também tem um
                            banco de baterias que permite que o usuário continue com energia mesmo em momentos de falha de fornecimento da concenssionária. Essa usina
                            acaba tendo um custo maior que a on grid devido ao custo das baterias, no entando é a instalação ideal para locais onde o fornecimento de 
                            eletricidade é de má qualidade.</p>
                        <center>
                            <img src="./imagens/comoEnergiaSolarFunciona/hibrido.jpg" alt="Como funciona um sistema híbrido"/>
                        </center>
                    </section>
                    <div>
                        <a  href="#cabecalho" class="retornar">                        
                            Voltar ao início
                        </a>
                    </div>
                    
                </article>
            </Container>
        );
    }
}
    

export default ComoFuncionaEnergiaSolar;





