import React , {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link}  from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//css
import './index.css';

class MenuDeOpcoesContainer extends Component{
    constructor(props){
        super(props);
        this.state = this.props.configMenuContainer;
    }

    render(){
        return(
            <main className='coluna'>                
                <Row xl='auto' md='auto' xs='auto'>
                    <Col >
                        <a href={this.props.configMenu.primeiroBotao.link}>
                            <div className='botoesMenuContainer espacoLinhas'>       
                                <p><FontAwesomeIcon icon={this.props.configMenu.primeiroBotao.iconeDoBotao} />{this.props.configMenu.primeiroBotao.texto}</p> 
                            </div>
                        </a>                        
                    </Col>
                </Row>
                <Row md='auto' xs='auto' className='espacoLinhas'>                    
                    <Col >
                        <a href={this.props.configMenu.segundoBotao.link}>
                            <div className='botoesMenuContainer espacoLinhas'><FontAwesomeIcon icon={this.props.configMenu.segundoBotao.iconeDoBotao} />{this.props.configMenu.segundoBotao.texto}</div>
                        </a>
                    </Col >        
                </Row>
            </main>
            
        );
    }
}

export default MenuDeOpcoesContainer;