
import React , {Component} from 'react';
import {Carousel} from 'react-bootstrap';
import MenuDeOpcoesContainer from './MenuDeOpcoesContainer';

import "./index.css";

class ControlledCarousel extends Component{
  constructor(props){
    super(props);
    this.state =  {
      configCarrossel: {
        intervaloSlides: 5000,
        slides:[{
            key: 0,
            alt: ''
          }]
        }
      };
    this.imagem = null;
  }

  componentDidMount(){
    this.setState({configCarrossel : this.props.configCarrossel});
  }

  render(){
    return (
      <Carousel >
        <Carousel.Item interval={this.state.configCarrossel.intervaloSlides}>
          <img
            className="d-block w-100"
            src={this.props.configCarrossel.slides[0].imagem}
            alt={this.props.configCarrossel.slides[0].alt}
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={this.state.configCarrossel.intervaloSlides}>
          <img
            className="d-block w-100"
            src={this.props.configCarrossel.slides[1].imagem}
            alt={this.props.configCarrossel.slides[1].alt}
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={this.state.configCarrossel.intervaloSlides}>
          <img
            className="d-block w-100"
            src={this.props.configCarrossel.slides[2].imagem}
            alt={this.props.configCarrossel.slides[2].alt}
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={this.state.configCarrossel.intervaloSlides}>
          <img
            className="d-block w-100"
            src={this.props.configCarrossel.slides[3].imagem}
            alt={this.props.configCarrossel.slides[3].alt}
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={this.state.configCarrossel.intervaloSlides}>
          <img
            className="d-block w-100"
            src={this.props.configCarrossel.slides[4].imagem}
            alt={this.props.configCarrossel.slides[4].alt}
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      
    );
  }
}


class Container extends Component{
  constructor(props){
    super(props);
    this.state = {
      configsContainer: props.configsContainer,
      primeiroBotao: props.configsContainer.loja       
    }
  }

  render(){
      return(
        <div className='conteudoContainer'>
          <ControlledCarousel configCarrossel={this.state.configsContainer.configCarrossel}/>                
          <MenuDeOpcoesContainer configMenu={this.state.configsContainer.configMenuContainer}/>        
        </div>            
      );
  };
}

export default Container;