import React from 'react';
import {Component} from 'react';

class ErroLogin extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <center>
                <img src="./imagens/dennis.gif" alt="Erro Login" />
                <h2> Ha, ha, haa!! Você não falou a palavra mágica!</h2>
            </center>
        );
    }
}

export default ErroLogin;
