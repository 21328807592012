
import {faFacebook, faWhatsapp, faLinkedin, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faMapMarked, faStore, faSolarPanel } from '@fortawesome/free-solid-svg-icons';

export let configuracaoHeader = {
    logo: 'imagens/header/simbolo_novo_Sol_e_Vento.png',
    textoTopo: 'Tudo em iluminação led em Capão da Canoa - RS',
    links: [
        {
            key: 0,
            texto: 'Lâmpadas Led',
            endereco: '/paginaEmConstrucao'
        },
        {
            key: 1,
            texto: 'Luminárias',
            endereco: '/paginaEmConstrucao'
        },
        {
            key: 2,
            texto: 'Refletores',
            endereco: '/paginaEmConstrucao'
        }
    ],
    dropdown: [
        {
            key: 0,
            texto: 'Acesso Previlegiado',
            endereco: '/login'
        }
        
    ]
};

export let configuracaoFooter = {
            linksRedesSociais: {
            facebook: {
                icone: faFacebook,
                link: 'https://www.facebook.com/soleventocapao'                
            },
            whatsapp: {
                icone: faWhatsapp,
                link: 'https://wa.me/message/NE4D34TO5R77O1'
            },
            linkedin: {
                icone: faLinkedin,
                link: 'https://www.linkedin.com/in/elisomorovskimedeiros-b3932338/'
            },
            instagram: {
                icone: faInstagram,
                link: 'https://www.instagram.com/sol_e_vento_oficial/'
            }
        },
        colunaEsquerda: {
            titulo: 'Sol&Vento 2021',
            linhas: [
                {
                    texto: 'CNPJ: 32.043.018/0001-70',
                    key: 0
                },
                {
                    texto: 'Telefone: (51)9.9310.1122',
                    key: 1
                },
                {
                    texto: 'Email: contato.solevento@gmail.com',
                    key: 2
                },
                {
                    texto: 'Endereço: Av. Paragussu, 25, loja 1',
                    key: 3
                },
                {
                    texto: 'Bairro Arco Íris',
                    key: 4
                },
                {
                    texto: 'Capão da Canoa - RS',
                    key: 5
                }
            ]
        },
        colunaCentral: {
            titulo: '',
            linhas: []
        }
    }

    export let configuracaoContainer = {
        configCarrossel: {
            intervaloSlides: 5000,
            slides:[{
                key: 0,
                imagem: 'imagens/carrossel/foto1_carrossel.jpg',
                alt: 'Primeira imagem'
            },{
                key: 1,
                imagem: 'imagens/carrossel/foto2_carrossel.jpg',
                alt: 'Segunda imagem'
            },{
                key: 2,
                imagem: 'imagens/carrossel/foto3_carrossel.jpg',
                alt: 'Terceira imagem'
            },{
                key: 3,
                imagem: 'imagens/carrossel/foto4_carrossel.jpg',
                alt: 'Quarta imagem'
            },{
                key: 4,
                imagem: 'imagens/carrossel/foto5_carrossel.jpg',
                alt: 'Quinta imagem'
            }],
        },configMenuContainer: {
            primeiroBotao: {
                texto: '  CONFIRA NOSSA LOJA',
                link: 'https://solevento.smartpos.app/',
                iconeDoBotao: faStore
            },
            segundoBotao: {
                texto: '  NOSSA LOCALIZAÇÃO',
                link: 'https://g.page/soleventocapao?share',
                iconeDoBotao: faMapMarked
            },
            terceiroBotao: {
                texto: '  ENERGIA SOLAR FUNCIONA',
                link: '/comoFuncionaEnergiaSolar',
                iconeDoBotao: faSolarPanel
            }
        }    
    }




