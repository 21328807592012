import React , {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Nav, Navbar, NavDropdown, Container} from 'react-bootstrap';


import "./index.css";
class Header extends Component{
    constructor(props){
        super(props);
    }
   
    render(){        
        return(
            <header  id='cabecalho'>
                <Navbar collapseOnSelect expand="lg">
                    <Container>
                        <Navbar.Brand >
                            <Link to="/">
                                <img id="logotipoSite" src={this.props.configsHeader.logo} height="100" className="d-inline-block align-top" alt="" />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Brand id='titulo_logotipo' className='link-cabecalho'>
                            <Link to="/" id='link-titulo-logotipo'>
                             <h1><span className="logo_header">Sol</span><span id='e_comercial' className="logo_header">&</span><span className="logo_header">Vento</span></h1>
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarResponsive" />
                        <Navbar.Collapse id="navbarResponsive" className='fonte-cabecalho'>
                            <Nav className="ml-auto">
                                {
                                    this.props.configsHeader.links.map((link) => {
                                        return(<Nav.Link><NavLink to={link.endereco} key={link.key}>{link.texto}</NavLink></Nav.Link>);
                                    })
                                }
                                
                                <NavDropdown title="Opções" id="basic-nav-dropdown">
                                    {
                                        this.props.configsHeader.dropdown.map((dropdown) => {
                                            return( <NavDropdown.Item key={dropdown.key}><Link to={dropdown.endereco}>{dropdown.texto}</Link></NavDropdown.Item>);
                                        })
                                    }                                    
                                    <NavDropdown.Divider />
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Nav id='titulo2'>
                    <Container>
                        <h4>
                            {this.props.configsHeader.textoTopo}
                        </h4> 
                    </Container>                                      
                </Nav>
            </header>            
        )
    };
}

export default Header;